
<section class="bg-half bg-light d-table w-100" id="home"
  style="background: url('assets/images/company/noticiasPortada.jpg'); background-size: 100% 160%;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h1 class="display-4 title-dark text-white fw-bold mb-3 text-white title-dark title"> Noticias </h1>
          <h3 class="text-white para-desc mb-0 mx-auto" style="font-size: x-large;">En Celso Garro Valderrama contamos
            con las Noticias de nuestro avance.</h3>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                <li class="breadcrumb-item active" aria-current="page">Noticias</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mt-4 pt-2">
        <div class="card work-container work-modern rounded border-0 overflow-hidden">
          <div class="card-header">
            <h1></h1>
            <p></p>
          </div>
          <div class="card-body p-0">
            <img src="assets/images/colegio/FOTOSGENERALES/fotogenerica-1.jpeg" class="img-fluid rounded"
              alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
            </div>
            <div class="read_more bg-primary text-center rounded-circle">
              <a href="javascript:void(0)" class="text-light d-block">
                <i-feather name="chevron-right" class="fea icon-sm title-dark"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-6 mt-4 pt-2">
        <div class="card work-container work-modern rounded border-0 overflow-hidden">
          <div class="card-header">
            <h1></h1>
            <p></p>
          </div>
          <div class="card-body p-0">
            <img src="assets/images/colegio/FOTOSGENERALES/fotogenerica-2.jpeg" class="img-fluid rounded"
              alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <ul class="post-meta mb-0 mt-2 text-white list-unstyled">
                <li class="list-inline-item me-3" style="font-size: x-large;"><i
                    class="uil uil-bed-double me-2"></i>noticia</li>

              </ul>
            </div>
            <div class="read_more bg-primary text-center rounded-circle">
              <a href="javascript:void(0)" class="text-light d-block">
                <i-feather name="chevron-right" class="fea icon-sm title-dark"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-4 pt-2">
        <div class="card work-container work-modern rounded border-0 overflow-hidden">
          <div class="card-header">
            <h1></h1>
            <p></p>
          </div>
          <div class="card-body p-0">
            <img src="assets/images/colegio/FOTOSGENERALES/fotogenerica-3.jpeg" class="img-fluid rounded"
              alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">

            </div>
            <div class="read_more bg-primary text-center rounded-circle">
              <a href="javascript:void(0)" class="text-light d-block">
                <i-feather name="chevron-right" class="fea icon-sm title-dark"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-6 mt-4 pt-2">
        <div class="card work-container work-modern rounded border-0 overflow-hidden">
          <div class="card-header">
            <h1></h1>
            <p></p>
          </div>
          <div class="card-body p-0">
            <img src="assets/images/colegio/FOTOSGENERALES/fotogenerica-4.jpeg" class="img-fluid rounded"
              alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
            </div>
            <div class="read_more bg-primary text-center rounded-circle">
              <a href="javascript:void(0)" class="text-light d-block">
                <i-feather name="chevron-right" class="fea icon-sm title-dark"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-4 pt-2">
        <div class="card work-container work-modern rounded border-0 overflow-hidden">
          <div class="card-header">
            <h1></h1>
            <p></p>
          </div>
          <div class="card-body p-0">
            <img src="assets/images/colegio/FOTOSGENERALES/fotogenerica-5.jpeg" class="img-fluid rounded"
              alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">

            </div>
            <div class="read_more bg-primary text-center rounded-circle">
              <a href="javascript:void(0)" class="text-light d-block">
                <i-feather name="chevron-right" class="fea icon-sm title-dark"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-6 mt-4 pt-2">
        <div class="card work-container work-modern rounded border-0 overflow-hidden">
          <div class="card-header">
            <h1></h1>
            <p></p>
          </div>
          <div class="card-body p-0">
            <img src="assets/images/colegio/FOTOSGENERALES/fotogenerica-6.jpeg" class="img-fluid rounded"
              alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">

            </div>
            <div class="read_more bg-primary text-center rounded-circle">
              <a href="javascript:void(0)" class="text-light d-block">
                <i-feather name="chevron-right" class="fea icon-sm title-dark"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</section>

<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
