
<section class="bg-half d-table w-100" id="home"
    style="background: url('assets/images/company/nosotrosPortada.jpg'); background-size: 100% 100%;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3 text-white title-dark title"> Nosotros </h1>
                    <h3 class="text-white para-desc mb-0 mx-auto" style="font-size: x-large;">Juntos formamos una gran
                        familia, comprometidos con la enseñanza de calidad.</h3>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Nosotros</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColors"></path>
        </svg>
    </div>
</div>
<section class="section">
    <div class="container">
        <div class="row align-items-center" id="counter">
            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">
                    <div class="d-flex mb-4">
                        <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold"
                                [CountTo]="anioActual-2009" [from]="0" [duration]="4"></span> </span>
                        <h3 class="align-self-end ms-2" style="font-size: x-large;">Años De Experiencia</h3>
                    </div>
                    <div class="section-title">
                        <h2 class="mb-4">Nuestra Misión</h2>
                        <p style="font-size: medium; font-size: large;">En la institución educactiva <span
                                class="text-primary fw-bold" style="font-family: 'Tillana'">Celso Garro</span> tenemos
                            servicios públicos en la modalidad presencial y/o virtual que promueve el desarrollo y la
                            formación integral de los estudiantes, con docentes capacitados que promueven buenas
                            prácticas pedagógicas, innovadoras y de emprendimiento utilizando las experiencias TIC;
                            prestando atención a las necesidades de aprendizaje e inclusivas, para construir una
                            ciudadanía basada en el respeto de los espacios democráticos, frente a las diversas crisis
                            de pandemias, siendo participativos en la conservación del medio ambiente.</p>

                        <h2 class="mb-4">Nuestra Visión</h2>
                        <p style="font-size: medium; font-size: large;">Todos y todas desarrollan aprendizajes para
                            ejercer una ciudadanía plena, comunicación efectiva para interactuar con otras personas,
                            práctica de una vida saludable para su bienestar, resolución de problemas para mejorar la
                            calidad de vida y una cultura digital para interactuar con la información a través de buenas
                            prácticas pedagógicas, de innovación y emprendimiento, con una Gestión Escolar centrado en
                            el estudiante quien es protagonista de su propio aprendizaje, conviviendo y dialogando en
                            una sociedad democrática, equitativa, intercultural e inclusiva que valora la diversidad y
                            están comprometidos con el desarrollo sostenible en lo social, ambiental y económico de su
                            entorno.</p>
                        <a href="javascript:void(0)" routerLink="/inicio/contacto" class="btn btn-primary mt-3"
                            style="font-size: large;">Contáctenos</a>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <img src="assets/images/colegio/portada1xx.jfif" class="img-fluid" alt="">
                
                <img src="assets/images/company/noticiasPortada.jpg" class="img-fluid" alt="">
            </div>
        </div>
    </div>

</section>

<section class="section bg-light">
    <div class="container mt-100 mt-60" style="margin-top: -15px;">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 order-1 order-md-2">
                <h4 class="title mb-4">Organigrama.</h4>
                <img src="assets/images/colegio/organigrama.png" class="img-fluid rounded shadow-md" alt="">
            </div>

            <div class="col-lg-5 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title me-lg-5">
                    <h4 class="title mb-4">Preguntas frecuentes:</h4>
                </div>

                <div class="faq-content mt-4 pt-2">
                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">¿Qué ofrece Celso Garro Valderrama?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Educación de calidad al alcance de Ud.</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">¿Donde se encuentra Celso Garro Valderrama?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Nos encontramos en el centro de la ciudad
                                </p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">¿Con qué comodidades cuenta Celso Garro Valderrama?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Celso Garro Valderrama cuenta con infraestructura de ultima
                                    generación para dar comodidad al estudiante y pueda realizar de manera óptima sus
                                    actividades.</p>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="section bg-light pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="video-solution-cta position-relative" style="z-index: 1;">
                    <div class="position-relative">
                        <img src="../../../assets/images/colegio/FOTOSGENERALES/fotogenerica-3.jpeg"
                            class="img-fluid rounded-md shadow-lg" alt="">
                        <div class="play-icon">

                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="feature-posts-placeholder bg-primary"></div>
    </div>
</section>

<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
