import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.component.html'
})
export class GestionComponent implements OnInit {
  
  navClass = 'nav-light';

  constructor() { }

  ngOnInit(): void {
  }
 
}
