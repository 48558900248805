import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-innovacion',
  templateUrl: './innovacion.component.html'
})
export class InnovacionComponent implements OnInit {
 
  navClass = 'nav-light';
  constructor() { }

  ngOnInit(): void {
  }
  
}
