
<section class="main-slider" id="home">
    <ngb-carousel class="slides" [showNavigationArrows]="showNavigationArrows"
    [showNavigationIndicators]="showNavigationIndicators">
        <ng-template ngbSlide>
            <li class="bg-home bg-animation-left d-flex align-items-center"
                style="background-image:url('{{ carruselData[0].urlImage }}'); margin-top: -25px;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-7">
                            <div class="position-relative mt-4" style="z-index: 1;">
                                <h1 class="heading mb-3 display-4 title-dark fw-bold title-dark">{{ carruselData[0].h1
                                    }} {{ carruselData[0].h1Parrafo }}
                                </h1>
                                <h2 class="para-desc">{{ carruselData[0].h4 }}</h2>
                                <div class="watch-video mt-4 pt-2">
                                    <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
                                        data-type="youtube" data-id="yba7hPeTSjk"
                                        class="btn btn-icon btn-pills btn-primary">
                                        <i-feather name="video" class="icons"></i-feather>
                                    </a><span class="fw-bold text-uppercase small align-middle ms-2">{{
                                        carruselData[0].span }}</span>
                                    <ng-template #content let-modal>
                                        <div class="modal-header">
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <iframe class="marcovideo" height="450px" width="780px"
                                                src="https://www.youtube.com/embed/1Z7Jx3g9u58"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen>
                                            </iframe>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ng-template>
        <ng-template ngbSlide>
            <li class="bg-home bg-animation-left d-flex align-items-center"
                style="background-image:url('{{ carruselData[1].urlImage }}'); margin-top: -25px;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-7">
                            <div class="position-relative mt-4" style="z-index: 1;">
                                <h1 class="heading mb-3 display-4 title-dark fw-bold title-dark" style="color: white;">
                                    {{carruselData[1].h1}}</h1>
                                <h2 class="para-desc text-white">{{ carruselData[1].h4 }}</h2>
                                <div class="watch-video mt-4 pt-2">
                                    <a routerLink="nosotros" class="btn btn-primary"><i class="uil uil-heart-alt"></i>{{
                                        carruselData[1].link }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ng-template>
        <ng-template ngbSlide>
            <li class="bg-home bg-animation-left d-flex align-items-center"
                style="background-image:url('{{ carruselData[2].urlImage }}'); margin-top: -25px;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-7">
                            <div class="position-relative mt-4" style="z-index: 1;">
                                <h1 class="heading mb-3 display-4 title-dark fw-bold title-dark">{{ carruselData[2].h1
                                    }} <br>{{ carruselData[2].h1Parrafo }}</h1>
                                <h2 class="para-desc">{{ carruselData[2].h4 }}</h2>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ng-template>
    </ngb-carousel>
</section>
<section class="section-two bg-light" id="bookroom">
    <div class="container">
        <div class="row justify-content-center">
            <app-reserva-inicio id="#reserva" (obtenerDatosReserva)="obtenerDatosReserva($event)"></app-reserva-inicio>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h1>Presentación</h1>

                    <p
                        style="text-align: justify; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                        <img src="assets/images/colegio/fotopresentacion.jpeg"
                            style="float: left;padding-right: 1.5rem; height: 40%;width: 40%;">Me dirijo a las y los
                        seguidores de nuestra Institución Educativa
                        N° 86253 “Celso Garro Valderrama” del Centro Poblado de
                        Pampan, Provincia de Bolognesi, Región Ancash, en forma
                        especial a nuestra comunidad educativa y local, con la finalidad
                        de expresar el cordial saludo en mi condición de Director, al
                        mismo tiempo; aprovecho el presente para hacer la presentación
                        pública de nuestro sitio Web, como una herramienta de gestión
                        escolar que tiene como propósito fortalecer el liderazgo de la
                        comunidad educativa hacía el logro de aprendizajes.<br><br>
                        A través del presente pretendemos la participación, el
                        involucramiento y la comunicación de nuestra comunidad
                        educativa en dimensiones de gestión, operando la funcionalidad
                        de los comités de gestión estratégica, de condiciones operativas, pedagógica y del bienestar,
                        direccionando el sentido de la gestión hacia el logro de los compromisos de gestión escolar en
                        beneficio de la formación integral de nuestros estudiantes, garantizando su acceso al sistema
                        educativo peruano y su conclusión satisfactoria de su perfil de egreso.<br><br>
                        El lanzamiento de esta plataforma se realiza en el marco de haber ganado el II, IV y V Concurso
                        Nacional de Proyectos de Innovación Pedagógica organizado por el FONDEP, el año 2020 con el
                        Proyecto “Gestionando las condiciones digitales logramos aprendizajes a distancia”, y; el año
                        2022 con el Proyecto “Comunidad Educativa Líder, hacía una gestión de aprendizajes”; el año 
                        2023 con el Proyecto "Comunidad Educativa Lider en Gestión Dimnesional, Comprometidos con los
                        Aprendizajes hacia una Ciudadanía Plena".<br><br>
                        Los proyectos mencionados tienen el objetivo de mejorar las buenas prácticas docentes, el primero
                        promueve el uso de las principales herramientas digitales al servicio de los propósitos de
                        aprendizajes de nuestros estudiantes especialmente en un escenario de aprendizaje híbrido, y;
                        el segundo promueve el acceso de la comunidad educativa líder y su presencia hacia el logro de
                        una gestión de aprendizajes a favor de nuestros estudiantes, el tercero promueve el liderazgo 
                        de las tres dimnesiones de gestión: Pedagogica, Administrativa y Comunal con la finalidad de 
                        lograr aprendizajes rumbo a una ciudadania plena<br><br>
                        Estaremos muy atentos a recibir sus sugerencias para mejorar y enriquecer los contenidos de
                        nuestra plataforma, poniendo a vuestra disposición para su conocimiento y emitir sus
                        comentarios. Gracias por su atención.<br><br></p>
                    <h5> Marcos Victor Llallihuaman Antunez<br>
                        DIRECTOR </h5>
                </div>
            </div>
        </div>

    </div>

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h1>Niveles Educativos</h1>
                    <h3 style="font-size: x-large; color: blue;">En la I.E.N° 86253 <span class="text-primary fw-bold"> "Celso Garro
                            Valderrama"</span> disfruta de la mejor educacion.
                    </h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul ngbNav #nav="ngbNav" [activeId]="showNgbNav(showRoomList)"
                    class="nav nav-pills shadow flex-column flex-sm-row d-md-inline-flex mb-0 p-1 bg-white rounded position-relative overflow-hidden"
                    id="pills-tab" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item m-1 ">
                        <a ngbNavLink class="nav-link py-2 px-5 rounded ">
                            <div class="text-center">
                                <h6 class="mb-0">primaria</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row" style="text-align: center; align-items: center">
                                <div class="col">
                                    <img src="assets/images/colegio/docentes-primaria.png">
                                </div>
                            </div>
                            <div class="row" style="margin-top: 20px">
                                <img src="assets/images/colegio/tabla-primaria.png">
                            </div>

                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4>ACTIVIDADES DE APRENDIZAJE 
                                            NIVEL PRIMARIA.</h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/DocentesActualesR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/estudiantesPrimariR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/primeroysegundoPrimaria/tutoraFabianAntaurco.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/primeroysegundoPrimaria/ActividadesAprendizajeR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/terceroPrimaria/tutoraLuzValderramaR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/terceroPrimaria/actividadesAprendizaje3R.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>

                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/cuartoPrimaria/tutoraNelsyZulemaR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/cuartoPrimaria/actividadesAprendizaje4R.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>

                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/quintoPrimaria/tutoraYoysyRosasR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/quintoPrimaria/ActividadesAprendizaje5R.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/sextoPrimaria/tutorRoberVelasquezR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/PRIMARIA/sextoPrimaria/ActividadesAprendizaje6R.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>

                        </ng-template>
                    </li>

                    <li [ngbNavItem]="2" class="nav-item m-1">
                        <a ngbNavLink class="nav-link py-2 px-5 rounded">
                            <div class="text-center">
                                <h6 class="mb-0">Secundaria</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row" style="text-align: center; align-items: center">
                                <div class="col">
                                    <img src="assets/images/colegio/docentes-secundaria.png" style="height:250px">
                                </div>
                            </div>
                            <div class="row" style="margin-top: 20px">
                                <img src="assets/images/colegio/tabla-secundaria.png">
                            </div>

                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4>ACTIVIDADES DE APRENDIZAJE
                                            NIVEL SECUNDARIA.</h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/SECUNDARIA/profesoresSecundariaR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <br>
                                        <br>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/SECUNDARIA/alunmosSecundaria.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/SECUNDARIA/primeroSecundariaR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/SECUNDARIA/segundoSecundariaR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/SECUNDARIA/terceroSecundariaR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/SECUNDARIA/cuartoSecundariaR.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>

                            <div class="container">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                                        <h4></h4>
                                        <div
                                            class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <img src="../../../assets/images/colegio/SECUNDARIA/quintoSecundaria.png"
                                                    class="img-fluid" alt="work-image">
                                            </div>

                                        </div>
                                    </div>


                                </div>


                            </div>


                        </ng-template>
                    </li>


                </ul>
                <div [ngbNavOutlet]="nav" class="col-12 tab-content mt-5"></div>
            </div>
        </div>
    </div>
</section>

<section class="section bg-cta"
    style="background: url('assets/images/colegio/foto-panoramica.png') center center; background-size: 100% 200%;"
    id="cta">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h1 class="text-white">En La I.E.N° 86253 "Celso Garro Valderrama"</h1>
                    <h3 class="text-light para-dark mx-auto" style="font-size: x-large;">Disfruta de la mejor enseñanza.
                    </h3>
                    <a (click)="openModal(contentmodal)" href="javascript:void(0);"
                        class="play-btn border border-light mt-4 video-play-icon">
                        <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
                    </a>
                    <ng-template #contentmodal let-modal>
                        <div class="modal-header">
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <iframe class="marcovideo" height="450px" width="780px"
                                src="https://www.youtube.com/embed/VfQjUqrwh8A" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen>
                            </iframe>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</section>
<app-services [servicesData]="servicesData"></app-services>
<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<app-cartillas [cartillaData]="cartillaData"></app-cartillas>
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-6 p-0 ps-md-3 pr-md-3">
                <div class="card map map-height-two rounded map-gray border-0">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3927.7503353584443!2d-77.15969248620799!3d-10.119510792769262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91a8330d221e2755%3A0xe9769e4a265b24eb!2sColegio%20Nacional%20Celso%20Garro%20Valderrama%20Pampan!5e0!3m2!1ses!2spe!4v1663966265191!5m2!1ses!2spe"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>
            </div>

            <div class="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card rounded shadow border-0">
                    <div class="card-body py-5">
                        <h2 class="card-title">Contáctenos !</h2>

                        <div class="custom-form mt-4">
                            <div id="message"></div>
                            <form class="p-4 shadow bg-white rounded" autocomplete="off" [formGroup]="contactenosForm"
                                (ngSubmit)="enviarContacto()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label class="form-label" style="font-size: x-large;">Nombre <span
                                                    class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                <input class="form-control ps-5" type="text" formControlName="nombre"
                                                    id="nombre" placeholder="Su nombre :">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label class="form-label" style="font-size: x-large;">Email <span
                                                    class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="email" class="fea icon-sm icons"></i-feather>
                                                <input class="form-control ps-5" type="email" formControlName="email"
                                                    id="email" placeholder="Su email :">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label class="form-label" style="font-size: x-large;">Comentarios</label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                                                <textarea class="form-control ps-5" type="text"
                                                    formControlName="comentarios" id="comentarios"
                                                    placeholder="Sus Comentarios :"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-center">
                                        <div class="d-grid">
                                            <input class="submitBnt btn btn-primary" type="submit" id="search"
                                                name="search" value="Enviar Mensaje">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
