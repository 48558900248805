<section class="bg-half d-table w-100" id="home"
    style="background: url('assets/images/company/innovacionPortada.jpg'); background-size: 100% 160%;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
                <div class="title-heading" style="height:250px;">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3">Innovación</h1>
                    <h3 class="para-desc title-dark mx-auto text-light" style="font-size: x-large;">Colaboracion para el
                        crecimiento y el desarrollo de nuestra institución.</h3>

                </div>
            </div>

        </div>

    </div>
</section>

<section class="section border-top">
    <div class="container">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/company/diploma.jpg" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">UGEL BOLOGNESI</h2>
                        <p style="font-size: large;">Nuestro compromiso por dar una excelente educación se nota en los
                            reconocimientos.</p>
                        <div class="mt-4 pt-2">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>



<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>