<section class="bg-half d-table w-100" id="home"
    style="background: url('assets/images/company/normatividadesPortada.png'); background-size: 100% 100%;height: 1000px;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3 text-white title-dark title"> Normatividades
                    </h1>
                    <h3 class="text-white para-desc mb-0 mx-auto" style="font-size: x-large;">Nuestras normatividades.
                    </h3>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Normatividades</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<section class="section bg-light">
    <div class="container mt-100 mt-60" style="margin-top: -15px;">
        <div class="row align-items-center">

            <div class="col-lg-12 col-md-12 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0 ">
                <div class="section-title me-lg-5">
                    <h3 style="font-size: large; color: blue;"> Normatividades <span
                            class="text-primary fw-bold">!!!</span></h3>
                </div>

                <div class="faq-content mt-4 pt-2">
                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark"> --> Oficios
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">En Proceso...</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark"> --> Resoluciones
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">En Proceso...
                                </p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark"> --> Directivas
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">En Proceso...</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark"> --> Instructivos
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">En Proceso...</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark"> --> Todas las normas
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">En Proceso...</p>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="section bg-light pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="video-solution-cta position-relative" style="z-index: 1;">
                    <div class="position-relative">
                        <img src="../../../assets/images/colegio/FOTOSGENERALES/fotogenerica-1.jpeg"
                            class="img-fluid rounded-md shadow-lg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="feature-posts-placeholder bg-primary"></div>
    </div>
</section>

<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>