
<section class="bg-half bg-light d-table w-100" id="home"
    style="background: url('assets/images/company/gestionPortada.jpg'); background-size: 100% 100%;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3 text-white title-dark title"> Gestión</h1>
                    <h3 class="text-white para-desc mb-0 mx-auto" style="font-size: x-large;">Disfruta de la mejor
                        educación.</h3>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Gestión</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<section class="section border-top">
    <div class="container">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-12">
                    <div class="col-lg-12">
                        <div class="card-body section-title p-md-5"
                            style="text-align: center; background: white; border: 0px solid">
                            <img src="assets/images/colegio/organigrama1.png" class="img-fluid" alt=""
                                style="width: 1500px;height:350px">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h1 class="mb-4">DIMENSIÓN ESTRATEGICA</h1>
                        <p style="font-size: large;">Referida a la generación de una orientación clara y compartida de
                            los fines y propósitos educativos de la IE, a partir de la cual se formulan objetivos y
                            metas a través de los instrumentos de gestión. El propósito de esta gestión es lograr una
                            visión integral de los recursos, oportunidades y necesidades propias de cada comunidad
                            educativa y de la IE, para lo cual debe considerar de cerca las otras dimensiones de
                            gestión. El principal criterio orientador de la dimensión estratégica es el cumplimiento de
                            los compromisos de gestión escolar por resultados (CGE 1 y CGE 2):<br><br>
                            CGE 1: Desarrollo integral de las y los estudiantes<br>
                            CGE 2: Acceso de las y los estudiantes al SEP hasta la culminación de su trayectoria
                            educativa.<br><br>
                            Esta dimensión es liderada por el Director y el CONEI y operativizada por nuestra Comunidad
                            Educativa.

                            .</p>

                    </div>
                </div>


                <div class="col-lg-6">
                    <div class="card rounded shadow border-0 bg-light overflow-hidden">
                        <div class="row no-gutters align-items-center">

                            <div class="col-lg-12">
                                <div class="card-body section-title p-md-5"
                                    style="text-align: center; background: white; border: 0px solid">
                                    <img src="assets/images/colegio/GESTION/gestionEstrategica.jpeg" class="img-fluid"
                                        alt="" style="width: 1500px;height:350px">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row no-gutters align-items-center">
                <div class="col-lg-12">
                    <div class="card-body section-title p-md-5" style="text-align: center;">
                        <p style="font-size: large;">
                            EL CONEI es un órgano de participación, concertación y vigilancia ciudadana, cuyos miembros
                            fueron elegidos para una vigencia de dos años (2021- 2022), reconocido mediante Resolución
                            Directoral N° 005- 2021.</p>
                        <h4>RESULTADOS DE GESTIÓN 2020- 2021- 2022</h4>
                        <h5>NIVEL PRIMARIA:</h5>
                        <img src="../../../assets/images/colegio/GESTION/resultadoGP1.png" alt=""><br><br>
                        <img src="../../../assets/images/colegio/GESTION/resultadoGP2.png" alt=""><br><br>
                        <img src="../../../assets/images/colegio/GESTION/resultadoGP3.png" alt=""><br><br>
                        <img src="../../../assets/images/colegio/GESTION/resultadoGP4.png" alt=""><br><br>

                        <h4>GRADO DE EFICIENCIA INTERNA</h4>
                        <img src="../../../assets/images/colegio/GESTION/gradoEficienciaInterna.png" alt=""><br><br>
                        <h5>NIVEL SECUNDARIA:</h5>
                        <img src="../../../assets/images/colegio/GESTION/porsentajeDeEstudiantes1.jpeg" alt=""><br><br>
                        <img src="../../../assets/images/colegio/GESTION/porsentajeDeEstudiantes2.jpeg" alt=""><br><br>
                        <img src="../../../assets/images/colegio/GESTION/porsentajeDeEstudiantes3.png" alt=""><br><br>
                        <img src="../../../assets/images/colegio/GESTION/porsentajeDeEstudiantes4.png" alt=""><br><br>
                        <h4>GRADO DE EFICIENCIA INTERNA</h4>
                        <img src="../../../assets/images/colegio/GESTION/gradoEficienciaInterna2.png" alt=""><br><br>

                        <h5>INSTRUMENTOS DE GESTIÓN (II.GG):</h5>

                        <p><strong> &nbsp;&nbsp;Proyecto Educativo Institucional (PEI): &nbsp;&nbsp; </strong> Orienta
                            la gestión escolar según corresponda en el mediano plazo (2022-2026)</p>
                        <div class="row">
                            <a style="font-size: large; padding-bottom: 0.5rem;"
                                href="../../../assets/images/colegio/PEI-2022.pdf" target="_blank">INSTITUCION EDUCATIVA
                                N° 86253(PCI 2022.pdf) </a>
                        </div>
                        <p><strong> &nbsp;&nbsp;Plan Anual de Trabajo (PAT 2022): &nbsp;&nbsp; </strong> Concreta los
                            objetivos y metas del PEI e incluye las actividades mínimas definidas sectorialmente para el
                            desarrollo del periodo lectivo.</p>
                        <div class="row">
                            <a style="font-size: large; padding-bottom: 0.5rem;"
                                href="../../../assets/images/colegio/PAT 2022_Anexos.pdf" target="_blank">PLAN ANUAL DE
                                TRABAJO(PAT 2022.pdf) </a>
                        </div>

                        <p><strong> &nbsp;&nbsp;Proyecto Curricular de la Institución Educativa (PCI 2022): &nbsp;&nbsp;
                            </strong> Orienta los procesos pedagógicos de la Institución Educativa para el desarrollo de
                            los aprendizajes establecidos en el CNEBR, alineando a lo establecido en el PEI, así como
                            las orientaciones de los modelos de servicio educativo.</p>
                        <div class="row">
                            <a style="font-size: large; padding-bottom: 0.5rem;"
                                href="../../../assets/images/colegio/PCI2022.pdf" target="_blank">PROYECTO CURRICULAR DE
                                LA INSTITUCION EDUCATIVA (PCI 2022.pdf) </a>
                        </div>


                        <p><strong> &nbsp;&nbsp;Reglamento Interno (RI 2022): &nbsp;&nbsp; </strong> Regula la
                            organización y funcionamiento integral de la I.E para promover una convivencia escolar
                            democrática y un clima favorable a los aprendizajes.</p>
                        <div class="row">
                            <a style="font-size: large; padding-bottom: 0.5rem;"
                                href="../../../assets/images/colegio/RI2022OFICIAL.pdf" target="_blank">REGLAMENTO
                                INTERNO (RI 2022.pdf) </a>
                        </div>

                    </div>

                </div>


            </div>

        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h1 class="mb-4">DIMENSIÓN ADMINISTRATIVA</h1>
                        <p style="font-size: large;">Lleva a cabo las prácticas establecidas por el CGE 3. <br><br> Esta
                            dimensión es liderada por el Comité de gestión de condiciones operativas y operativizada por
                            el personal de la IE que participe de las tareas involucradas.<br><br>
                            CGE 3: Gestión de las condiciones operativas orientada al sostenimiento del servicio
                            educativo ofrecido por la IE.<br><br>
                            Comité de Gestión de Condiciones Operativas: aprobada según la R.D. N° 008-2022
                            Plan de Trabajo del Comité de Gestión de Condiciones Operativas, aprobada según la R.D. N°
                            016-2022
                            .</p>

                    </div>
                </div>


                <div class="col-lg-6">
                    <div class="card rounded shadow border-0 bg-light overflow-hidden">
                        <div class="row no-gutters align-items-center">

                            <div class="col-lg-12">
                                <div class="card-body section-title p-md-5"
                                    style="text-align: center; background: white; border: 0px solid">
                                    <img src="assets/images/colegio/GESTION/gestionAdministrativa.jpeg"
                                        class="img-fluid" alt="" style="width: 1500px;height:350px">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row no-gutters align-items-center">
                <div class="col-lg-12">
                    <div class="card-body section-title p-md-5">

                        <h5>1) Seguridad y Salubridad</h5>

                        <p><strong> &nbsp;&nbsp;1.1. &nbsp;&nbsp; </strong>I.E. espacio seguro para la integridad física
                            de los estudiantes.</p>
                        <p><strong> &nbsp;&nbsp;1.2. &nbsp;&nbsp; </strong>I.E. libre de riesgo sanitario para los
                            estudiantes</p>
                        <p><strong> &nbsp;&nbsp;1.3. &nbsp;&nbsp; </strong>I.E. acondicionada ante situaciones de
                            emergencia y desastres</p>
                        <p><strong> &nbsp;&nbsp;1.4. &nbsp;&nbsp; </strong>I.E. organizada para actuar ante situaciones
                            de emergencia y desastres</p>
                        <p><strong> &nbsp;&nbsp;1.5. &nbsp;&nbsp; </strong>Personal organizado para la seguridad de los
                            estudiantes durante los momentos críticos</p>
                        <p><strong> &nbsp;&nbsp;1.6. &nbsp;&nbsp; </strong>Intervención del personal ante situaciones
                            que afectan la seguridad de los estudiantes</p>
                        <p><strong> &nbsp;&nbsp;1.7. &nbsp;&nbsp; </strong>Prevención de situaciones que amenacen la
                            salud, seguridad o integridad de los estudiantes.</p>

                        <h5>2) Gestión de los recursos educativos</h5>
                        <p><strong> &nbsp;&nbsp;2.1. &nbsp;&nbsp; </strong>Distribución oportuna y cobertura del
                            material educativo para los estudiantes enviado por el MINEDU</p>
                        <p><strong> &nbsp;&nbsp;2.2. &nbsp;&nbsp; </strong>Promoción del uso del material educativo
                            enviado por el MINEDU</p>
                        <p><strong> &nbsp;&nbsp;2.3. &nbsp;&nbsp; </strong>Promoción del uso de espacios y tecnología
                            con finalidad pedagógica</p>

                        <h5>3) Matrícula y preservación del Derecho a la Educación</h5>
                        <p><strong> &nbsp;&nbsp;3.1. &nbsp;&nbsp; </strong>Matrícula y permanencia de estudiantes sin
                            condicionamientos</p>
                        <p><strong> &nbsp;&nbsp;3.2. &nbsp;&nbsp; </strong>Acceso al servicio educativo sin
                            condicionamientos</p>
                        <p><strong> &nbsp;&nbsp;3.3. &nbsp;&nbsp; </strong>Cumplimiento del horario de trabajo del
                            personal docente y directivo</p>
                        <p><strong> &nbsp;&nbsp;3.4. &nbsp;&nbsp; </strong>Registro oportuno de matrícula en SIAGIE</p>
                        <p><strong> &nbsp;&nbsp;3.5. &nbsp;&nbsp; </strong>Implementación de estrategias de apoyo para
                            permanencia de estudiantes en situaciones de vulnerabilidad</p>

                        <h5>4) Gestión Transparente de los Recursos Financieros</h5>
                        <p><strong> &nbsp;&nbsp;4.1. &nbsp;&nbsp; </strong>Integridad en la gestión de los recursos
                            financieros de la I.E</p>
                        <p><strong> &nbsp;&nbsp;4.2. &nbsp;&nbsp; </strong>Constitución del comité de gestión de
                            condiciones operativas</p>
                        <p><strong> &nbsp;&nbsp;4.3. &nbsp;&nbsp; </strong>Generación o captación y uso de los recursos
                            financieros de la I.E. según normativa</p>
                        <p><strong> &nbsp;&nbsp;4.4. &nbsp;&nbsp; </strong>Registro oportuno en el sistema de
                            información de mantenimiento</p>
                        <p><strong> &nbsp;&nbsp;4.5. &nbsp;&nbsp; </strong>Gestión de recursos propios según lo
                            planificado y rendición de cuentas.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">

            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h1 class="mb-4">DIMENSIÓN PEDAGÓGICA</h1>
                        <p style="font-size: large;">Lleva a cabo las prácticas establecidas por el CGE 4. <br><br>Esta
                            dimensión es liderada por el Comité de gestión pedagógica y operativizada por el personal de
                            la IE que participe de las tareas involucradas.<br><br>
                            CGE 4: Gestión de la práctica pedagógica orientada al logro de aprendizajes previstos en el
                            perfil de egreso del CNEB.<br><br>

                            Comité de Gestión de los Procesos Pedagógicos: Aprobada según RD N° 009-2022<br><br>
                            Plan de Trabajo de la Comisión de Gestión de los Procesos Pedagógicos: Aprobada según R.D.
                            N° 014-2022.
                        </p>
                        <div class="mt-4 pt-2">

                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card rounded shadow border-0 bg-light overflow-hidden">
                        <div class="row no-gutters align-items-center">

                            <div class="col-lg-12">
                                <div class="card-body section-title p-md-5"
                                    style="text-align: center; background: white; border: 0px solid">
                                    <img src="assets/images/colegio/GESTION/gestionAdministrativa.jpeg"
                                        class="img-fluid" alt="" style="width: 1500px;height:350px">
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div class="row no-gutters align-items-center">
                <div class="col-lg-12">
                    <div class="card-body section-title p-md-5">
                        <h5> 1) Planificación Curricular </h5>
                        <p><strong> &nbsp;&nbsp;1.1. &nbsp;&nbsp; </strong>Planificación de las Experiencias de
                            Aprendizaje.</p>

                        <p><strong> &nbsp;&nbsp;1.2. &nbsp;&nbsp; </strong>Apoyo pedagógico para la planificación
                            curricular
                        <p><strong> &nbsp;&nbsp;1.3. &nbsp;&nbsp; </strong>Planificación de sesiones o actividades de
                            aprendizaje
                        <p><strong> &nbsp;&nbsp;1.4. &nbsp;&nbsp; </strong>Conocimientos suficientes para brindar
                            asesoría pedagógica
                        <p><strong> &nbsp;&nbsp;1.5. &nbsp;&nbsp; </strong>Espacios para la planificación colegiada.

                        <h5>2) Monitoreo de la práctica pedagógica en el aula</h5>
                        <p><strong> &nbsp;&nbsp;2.1. &nbsp;&nbsp; </strong>Muy alto cumplimiento del monitoreo
                        <p><strong> &nbsp;&nbsp;2.2. &nbsp;&nbsp; </strong>Organización de información obtenida en el
                            monitoreo
                        <p><strong> &nbsp;&nbsp;2.3. &nbsp;&nbsp; </strong>Análisis de los resultados obtenidos en el
                            monitoreo

                        <h5>3) Fortalecimiento del trabajo docente</h5>
                        <p><strong> &nbsp;&nbsp;3.1. &nbsp;&nbsp; </strong>Participación en acciones formativas
                            promovidas por la UGEL, DRE, MINEDU
                        <p><strong> &nbsp;&nbsp;3.2. &nbsp;&nbsp; </strong>Acciones para el fortalecimiento de
                            competencias docentes
                        <p><strong> &nbsp;&nbsp;3.3. &nbsp;&nbsp; </strong>Retroalimentación a la práctica pedagógica
                        <p><strong> &nbsp;&nbsp;3.4. &nbsp;&nbsp; </strong>Acciones para el fortalecimiento de
                            competencias docentes basadas en un diagnóstico
                        <p><strong> &nbsp;&nbsp;3.5. &nbsp;&nbsp; </strong>Fortalecimiento de las prácticas pedagógicas
                            mediante el trabajo colegiado.

                        <h5>4) Seguimiento de los aprendizajes</h5>
                        <p><strong> &nbsp;&nbsp;4.1. &nbsp;&nbsp; </strong>Gestión de entrega oportuna de informe de
                            progreso de las competencias.
                        <p><strong> &nbsp;&nbsp;4.2. &nbsp;&nbsp; </strong>Informes de progreso con niveles de logro
                        <p><strong> &nbsp;&nbsp;4.3. &nbsp;&nbsp; </strong>Generación de espacios para el análisis y la
                            reflexión de los aprendizajes, e identificación de oportunidades de mejora.
                        <p><strong> &nbsp;&nbsp;4.4. &nbsp;&nbsp; </strong>Estrategias para la mejora de aprendizajes
                            elaboradas sobre la base del análisis de los resultados del avance de los estudiantes.

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h1 class="mb-4">DIMENSIÓN COMUNITARIA</h1>
                        <p style="font-size: large;">Lleva a cabo las prácticas establecidas por el CGE 5. Esta
                            dimensión es liderada por el Comité de gestión del bienestar y operativizada por el personal
                            de la IE que participe de las tareas involucradas.<br><br>
                            CGE 5: Gestión del bienestar escolar que promueva el desarrollo integral de las y los
                            estudiantes.<br><br>
                            Comité de Gestión del Bienestar: aprobada según RD N° 010-2022
                            Plan de Trabajo del Comité de Gestión del Bienestar aprobada según RD N° 017-2022
                        </p>
                        <div class="mt-4 pt-2">

                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card rounded shadow border-0 bg-light overflow-hidden">
                        <div class="row no-gutters align-items-center">

                            <div class="col-lg-12">
                                <div class="card-body section-title p-md-5"
                                    style="text-align: center; background: white; border: 0px solid">
                                    <img src="../../../assets/images/colegio/GESTION/dimensionComunitaria.png"
                                        class="img-fluid" alt="" style="width: 1500px;height:350px">
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="row no-gutters align-items-center">
                <div class="col-lg-12">
                    <div class="card-body section-title p-md-5">
                        <h5>1) Participación de la Comunidad Educativa</h5>
                        <p><strong> &nbsp;&nbsp;1.1. &nbsp;&nbsp; </strong>Constitución de instancias formales de
                            participación de la comunidad educativa</p>
                        <p><strong> &nbsp;&nbsp;1.2. &nbsp;&nbsp; </strong>Promoción de participación de las familias
                        </p>
                        | <p><strong> &nbsp;&nbsp;1.3. &nbsp;&nbsp; </strong>Liderazgo democrático del director en la
                            gestión de la participación de docentes</p>
                        <p><strong> &nbsp;&nbsp;1.4. &nbsp;&nbsp; </strong>Funcionamiento del CONEI</p>

                        <h5>2) Relaciones Interpersonales en la Institución Educativa</h5>
                        <p><strong> &nbsp;&nbsp;2.1. &nbsp;&nbsp; </strong>Promoción de las relaciones interpersonales
                            entre el personal docente de la I.E.</p>
                        <p><strong> &nbsp;&nbsp;2.2. &nbsp;&nbsp; </strong>Muy buenas relaciones interpersonales del
                            director con los docentes de la I.E.</p>
                        <p><strong> &nbsp;&nbsp;2.3. &nbsp;&nbsp; </strong>Acciones para que las relaciones
                            interpersonales en la I.E. sean muy buenas</p>

                        <h5>3) Gestión de la Convivencia Escolar.</h5>
                        <p><strong> &nbsp;&nbsp;3.1. &nbsp;&nbsp; </strong>La I.E. cuenta con normas de convivencia
                            aprobadas</p>
                        <p><strong> &nbsp;&nbsp;3.2. &nbsp;&nbsp; </strong>La I.E cuenta con el libro de registro de
                            incidencias y personal responsable del registro en el portal SISEVE</p>
                        <p><strong> &nbsp;&nbsp;3.3. &nbsp;&nbsp; </strong>Identificación de situaciones de violencia
                            escolar</p>
                        <p><strong> &nbsp;&nbsp;3.4. &nbsp;&nbsp; </strong>Normas de convivencia elaboradas con
                            integrantes de la comunidad educativa y difundidas entre ellos</p>
                        <p><strong> &nbsp;&nbsp;3.5. &nbsp;&nbsp; </strong>Atención de situaciones de violencia escolar
                            en la I.E.</p>
                        <p><strong> &nbsp;&nbsp;3.6. &nbsp;&nbsp; </strong>Acciones para la prevención de situaciones de
                            violencia escolar</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
