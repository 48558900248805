

<section class="bg-half d-table w-100" id="home"
    style="background: url('assets/images/company/aliadosPortada.jpg'); background-size: 100% 160%;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
                <div class="title-heading" style="height:250px;">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3">Aliados Estrategicos</h1>
                    <h3 class="para-desc title-dark mx-auto text-light" style="font-size: x-large;">Colaboracion para el
                        crecimiento y el desarrollo de nuestra institución.</h3>

                </div>
            </div>

        </div>

    </div>
</section>

<section class="section border-top">
    <div class="container">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/fotosaliados/HuastaFoto.jpg" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">MUNICIPALIDAD DISTRITAL DE HUASTA</h2>
                        <p style="font-size: large;"><b>Dirección:</b> Jr. Bolognesi N° 125 Mz. C Lt. D Km. 11
                             - Huasta - Perú<br>
                            <b>Teléfono:</b> 943116648 <br>
                            <b>E-mail:</b> munihuasta@gmail.com <br>
                            <b>Sitio web oficial:</b> No disponible <br>
                            <b>Alcalde distrital de Huasta:</b> DONALD CALLUPE MARQUEZ </p>
                        <div class="mt-4 pt-2">
                            <a href="http://munihuasta.gob.pe/" target="_blank" class="btn btn-outline-primary">Ver
                                más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="../../../assets/images/colegio/aliados/municipalidadDePampan.png" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">MUNICIPALIDAD DEL CENTRO POBLADO DE PAMPAN</h2>
                        <p style="font-size: large;"> <b>Dirección:</b>  "Calle María Parado de Bellido Mz. E Lt. 3"<br>
 
                            <b>Centro Poblado:</b> Pampan <br>
                             
                            <b>Distrito:</b> Huasta <br>
                             
                            <b>Provincia:</b> Bolognesi <br>
                             
                            <b>Región:</b> Ancash<br>
                             
                            <b>Teléfono:</b> 986556403.</p>
                        <div class="mt-4 pt-2">
                            <a href="http://munihuasta.gob.pe/" target="_blank" class="btn btn-outline-primary">Ver
                                más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/fotosaliados/qaliwarma.png" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">QALIWARMA</h2>
                        <p style="font-size: large;">Somos un programa del Ministerio de Desarrollo e Inclusión Social (Midis) que garantiza la alimentación de los estudiantes de colegios públicos del país durante todos los días del año escolar, de acuerdo con sus características y las zonas donde viven.</p>
                        <div class="mt-4 pt-2">
                            <a href="https://www.gob.pe/qaliwarma" target="_blank" class="btn btn-outline-primary">Ver
                                más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/fotosaliados/centroSalud.jpg" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">PUESTO DE SALUD DE HUASTA</h2>
                        <p style="font-size: large;"><b>Nombre del establecimiento:</b> Puesto De Salud Huasta<br>
                            <b>Código único:</b> 1497<br>
                            <b>Clasificación:</b> Puestos De Salud O Postas De Salud<br>
                            <b>Tipo:</b> Establecimiento De Salud Sin Internamiento<br>
                            <b>Categoría:</b> I-2<br>
                            <b>Dirección:</b> Otros Huasta Sn Huasta Sn Huasta Bolognesi Ancash<br>
                            <b>Teléfono:</b> 979163907<br>
                            <b>Horario:</b> 08:00 – 14:00<br>
                            <b>Distrito:</b> Huasta<br>
                            <b>Provincia:</b> Bolognesi<br>
                            <b>Departamento:</b> Ancash<br>
                        <div class="mt-4 pt-2">
                            <a href="https://www.deperu.com/salud-nacional/establecimientos-de-salud-gbno-regional-minsa/huasta-huasta-1170"
                                target="_blank" class="btn btn-outline-primary">Ver más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/fotosaliados/cem.jpg" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">CEM</h2>
                        <p style="font-size: large;">Son servicios públicos especializados y gratuitos, de atención integral y multidisciplinaria, para víctimas de violencia contra las mujeres y los integrantes del grupo familiar y personas afectadas por violencia sexual. Permite acceder a asesoría legal, contención emocional y apoyo social a nivel nacional.
                            <br>
                            En todo el Perú, los 245 CEM regulares y un CEM en centro de salud (Santa Julia, Piura) <b>atienden de lunes a viernes de 8:00 a.m a 4:15 p.m</b>, y los 185 CEM en comisarías, las 24 horas del día, los 365 días del año.
                            <br>
                            Si deseas más información puedes llamar a la central telefónica <b>(01) 4197260</b>.</p>
                        <div class="mt-4 pt-2">
                            <a href="http://munihuasta.gob.pe/" target="_blank" class="btn btn-outline-primary">Ver
                                más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="../../../assets/images/colegio/aliados/policiaNacional.png" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">PNP</h2>
                        <p style="font-size: large;">Somos una institución del Estado que tiene por misión garantizar, mantener y restablecer el orden interno, prestar protección y ayuda a las personas y a la comunidad.<br>
                        Telefono: 105. Policía Nacional del Perú.
                        </p>
                        <div class="mt-4 pt-2">
                            <a href="https://www.policia.gob.pe/" target="_blank" class="btn btn-outline-primary">Ver
                                más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="../../../assets/images/colegio/aliados/apafa.png" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">APAFA</h2>
                        <p style="font-size: large;">La Asociación de Padres de Familia, es un órgano de colaboración con las actividades del colegio, a través del cual los padres de familia se organizan, colaboran y participan en las actividades que el Colegio propone. La Junta Directiva nexo entre el Director y los Padres de Familia.<br>

                            Para lograr sus objetivos, la Junta Directiva necesita contar con una activa participación de los Comités de cada sección. Juntos podemos lograr mejoras para que el centro de estudios de nuestros hijos sea ese lugar ideal, en el cual ellos vivan experiencias enriquecedoras que los acompañen toda la vida.<br>
                            
                            <b>Las principales funciones de la junta directiva son:</b><br>
                            * Elaborar el Plan de Trabajo Anual en coordinación con la Dirección.<br>
                            * Colaborar en los talleres de padres, actividades de responsabilidad social, de integración, deportivas, espirituales, culturales, etc.<br>
                            * Colaborar en la organización del Día Familiar del Colegio.<br>
                            * Participar en las actuaciones del Colegio.<br>
                            * Proponer ideas para el mejoramiento de la educación de nuestros hijos.</p>
                        <div class="mt-4 pt-2">

                            <a href="https://www.drelm.gob.pe/drelm/wp-content/uploads/2017/02/TallerAPAFA.pdf" target="_blank" class="btn btn-outline-primary">Ver
                                más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="../../../assets/images/colegio/aliados/standard_Elección del municipio escolar 2023.jpg" class="img-fluid" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h2 class="mb-4">MUNICIPIO ESCOLAR</h2>
                        <p style="font-size: large;">Un municipio escolar es una organización de alumnos en la escuela, a partir de la cual se desarrollan una serie de actividades que contribuirán a su posterior educación en la formación de valores y democracia.</p>
                        <div class="mt-4 pt-2">

                            <a href="https://dnef.jne.gob.pe/documentos/3-cartillaeducativa3.pdf" target="_blank" class="btn btn-outline-primary">Ver
                                más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>



<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
