<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block">
        <footer class="footer bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                        </a>
                        <p class="mt-4 text-muted">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item">
                                <a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a>
                            </li>
                        </ul>
                    </div>

                    

                   
                </div>
            </div>
        </footer>
    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        
                        <h5 class="mt-4"><span class="text-primary fw-bold">Celso Garro Valderrama</span> ofrece al publico en general nuestras redes sociales
                        </h5>
                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li class="list-inline-item">
                                <a href="https://www.facebook.com/" target="_blank" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a href="https://www.instagram.com/" target="_blank" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <ul class="list-unstyled footer-list mt-6" >
                            <li>
                                <a routerLink="nosotros" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i> Nosotros
                                </a>
                            </li>
                            <li>
                                <a routerLink="gestion" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i> Gestion
                                </a>
                            </li>
                            <li>
                                <a routerLink="noticias" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i> Noticias
                                </a>
                            </li>
                            <li>
                                <a routerLink="aliados" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i> Aliados
                                </a>
                            </li>
                            <li>
                                <a routerLink="innovacion" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i> Innovacion
                                </a>
                            </li>
                            <li>
                                <a routerLink="normatividades" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i> Normatividades
                                </a>
                            </li>
                        </ul>
                    </div>
                    

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0" style="text-align: end;">
                        <h6>"Estudio para trascender"</h6>
                        <img class="col-lg-8 col-md-12 col-6" style="margin-top:0px;" src="../../../assets/images/mundo/logoSinFondo.png" alt="">
                        
                    </div>
                </div>
            </div>
        </footer>
    </ng-template>
</div>
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">©
                        <script type="text/javascript" id="www-widgetapi-script" src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js" async=""></script>
                        <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script> {{year}} Diseñado con <i class="mdi mdi-heart text-danger"></i> por Wolfrit.
                    </p>
                </div>
            </div>
            
        </div>
    </div>
</footer>