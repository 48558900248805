<div class="container">
  <div class="row">
    <h5>Se encontró <span>{{ totalCoincidencias }}</span> coincidencia{{totalCoincidencias == 1 ? '' : 's'}}</h5>
  </div>
</div>
<section class="section">
  <div class="container">
    <div class="row align-items-center shadow bg-white rounded" *ngFor="let item of roomListData"
      style="margin-top: 20px; padding: 15px;">
      <div class="col-lg-3 col-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="card work-container work-modern rounded border-0 overflow-hidden">
          <div class="position-relative" (click)="enviar(item)">
            <img src="{{ item.imagen1 }}" class="rounded img-fluid mx-auto d-block" alt="">
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">{{ item.nombrehabitacion }}</h4>
          <p class="text-muted">{{ item.category }}</p>
          <p class="text-muted">Número de habitacion: {{ item.numerohabitacion }}</p>
          <p class="text-muted">Número de personas: {{ item.numerodepersonas }}</p>
          <p class="text-muted">Desde: <span class="text-success" style="color: blue;">S/. {{ item.preciohabitacion
              }}</span>x Noche</p>
        </div>
      </div>
      <div class="col-lg-2 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <a (click)="enviar(item)" class="btn btn-primary mt-3">Reservar<i class="uil uil-angle-right-b"></i></a>
      </div>
    </div>
  </div>

</section>



<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>