<header id="topnav" class="defaultscroll sticky"  (window:scroll)="windowScroll()" [class]="navClass"
    [ngClass]="{'tagline-height': sliderTopbar == true}">
    <div class="container" style="margin-top: 5px;">
        <div>
           
            <ul class="buy-button list-inline mb-0" *ngIf="buttonList == true">
                <li class="list-inline-item mb-0 ms-1">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i
                            class="uil uil-facebook-f icons"></i></a>
                </li>
                <li class="list-inline-item mb-0 ms-1">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i
                            class="uil uil-github icons"></i></a>
                </li>
                <li class="list-inline-item mb-0 ms-1">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i
                            class="uil uil-twitter icons"></i></a>
                </li>
                <li class="list-inline-item mb-0 ms-1">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i
                            class="uil uil-linkedin-alt icons"></i></a>
                </li>
            </ul>

            <a class="logo" routerLink="" *ngIf="navClass === 'nav-light'">
                <img src="assets/images/colegio/logo-colegio-celso.jpg" class="l-dark logo-dark-mode" height="55" alt=""
                    style="margin-right: -70px; margin-left: -70px; margin-top: 8px; ">
            </a>
        </div>
        <span *ngIf="!buttonList && !isdeveloper && !shopPages">
            <div class="buy-button">
                <ng-template [ngIf]="navClass === 'nav-light'">
                    <a routerLink="/" [ngxScrollTo]="'#bookroom'"
                        class="btn personalizado login-btn-primary">Intranet</a>
                </ng-template>
             
            </div>
        </span>
        
        <div id="navigation">
            <ul class="navigation-menu" [class]="navClass">
                <li><a class="nav-link-ref" routerLink=""
                        style="font-family: 'Lucida Sans', cursive; ">Inicio</a></li>
                <li><a class="nav-link-ref" routerLink="nosotros"
                        style="font-family: 'Lucida Sans', cursive; ">Nosotros</a></li>
                <li><a class="nav-link-ref" routerLink="gestion"
                        style="font-family: 'Lucida Sans', cursive; ">Gestión</a></li>
                <li><a class="nav-link-ref" routerLink="noticias"
                        style="font-family: 'Lucida Sans', cursive; ">Noticias</a></li>
                <li><a class="nav-link-ref" routerLink="aliados"
                        style="font-family: 'Lucida Sans', cursive; ">Aliados</a></li>
                <li><a class="nav-link-ref" routerLink="innovacion"
                        style="font-family: 'Lucida Sans', cursive; ">Innovacion</a></li>
                <li><a class="nav-link-ref" routerLink="normatividades"
                        style="font-family: 'Lucida Sans', cursive; ">Normatividades</a>
                </li>
            </ul>
        </div>
    </div>
</header>