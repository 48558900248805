<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<section class="section pb-0" id="principal">

    <div class="container shadow bg-white rounded" style="padding-top: 30px;">

        <div class="row align-items-center">

            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen1}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen2}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen3}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen4}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen5}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h1 class="heading mb-3 display-4 title-dark fw-bold title-dark">{{ habitacion.nombrehabitacion}}
                    </h1>
                    <h4 class="text-success">S/. {{ habitacion.preciohabitacion}} x Noche</h4>
                        <ul class="list-unstyled text-warning h1 mb-0">
                            <li class="list-inline-item h5" *ngFor="let item of habitacion.servicios; let i = index"><i
                                    class="mdi mdi-star"></i></li>
                        </ul>

                        <app-reserva-detalle id="#reserva" [habitacion]="habitacion"
                            (obtenerDatosReserva)="obtenerDatosReserva($event)" [prereserva]="prereserva">
                        </app-reserva-detalle>

                        <ul class="row list-unstyled text-muted" style="margin-top: 15px;">
                            <div class="col-lg-12 col-md-6">
                                <li class="mb-0" style="font-size: large;"><span class="text-primary h3 me-2"><i
                                            class="uil uil-tag-alt align-middle"></i></span> Categoria: <span
                                        style="color: rgb(0, 17, 255);">{{ categoria }}</span></li>
                                <li class="mb-0" style="font-size: large;"><span class="text-primary h3 me-2"><i
                                            class="uil uil-users-alt align-middle"></i></span> Para
                                    <span>{{habitacion.numerodepersonas}}</span> personas</li>
                            </div>
                        </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container shadow bg-white rounded" style="padding-top: 15px; margin-top: 15px;">

        <h3 class="mb-3">Comodidades</h3>

        <div class="row list-unstyled text-muted align-items-center">

            <div class="col-lg-4 col-md-6 col-sm-12">
                <li class="mb-0" style="font-size: large;" *ngFor="let item of listaServicios[0]; let i=index"><span
                        class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    {{item.nombreservicio}}
                </li>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <li class="mb-0" style="font-size: large;" *ngFor="let item of listaServicios[1]; let i=index"><span
                        class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    {{item.nombreservicio}}
                </li>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <li class="mb-0" style="font-size: large;" *ngFor="let item of listaServicios[2]; let i=index"><span
                        class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    {{item.nombreservicio}}
                </li>
            </div>
        </div>
    </div>
    <div class="container  mt-100 mt-60" style="margin-bottom: 50px;">
        <div class="row">
            <app-calendario [dataId]="habitacion.id"></app-calendario>
        </div>
    </div>

</section>

<a class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#principal'">
    <i-feather name="arrow-up" class="icons">Volver arriba</i-feather>
</a>