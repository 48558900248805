<section class="section">
    <div class="container pb-lg-4 mb-md-5 mb-4">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h1>Valores en la escuela</h1>
                    <h3 style="font-size: x-large; color: blue;">Nuestros valores en <span class="text-primary fw-bold">Celso
                            Garro Valderrama</span> .
                    </h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-12 mt-5" *ngFor="let data of servicesData">
                <div class="features features text-center">
                    <div class="image position-relative d-inline-block">
                        <i class="{{data.icon}}"></i>
                    </div>

                    <div class="content mt-4">
                        <h4>{{data.title}}</h4>
                        <p class="text-muted mb-0">{{data.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>